import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/setup/store.js';
import * as CONFIG from '@/setup/init.js'
import axios from 'axios'

// import Footer from '@/components/Footer.vue'
// import Header from '@/components/Header.vue'

//collect the langues
var langsArray = []
CONFIG.languages.forEach(element => {
  langsArray.push(element.code)
});

Vue.use(VueRouter)

  const routes = [        
    {
      path: '/admin', 
      name: 'admin',
      meta: {needAuth: false},
      components: {
        default: () => import('@/views/admin/AdminLogin.vue')        
      },
    },     
    {
      path: '/admin/invite', 
      name: 'adminPage',
      meta: {needAdminAuth: true},
      components: {
        default: () => import('@/views/admin/AdminPage.vue')        
      },
    },  
    { 
      path: "/:lang",              
      component: {
        render: (h) => h('router-view')                   
      },
      beforeEnter(to, from, next) {    
        const lang = to.params.lang;  
        if (!langsArray.includes(lang)) return next(store.state.lang.defLang)
        return next()                            
      },
      children: [   
        {
          path: 'login', 
          name: 'LogIn',
          meta: {needAuth: false},
          default: true,
          components: {
            default: () => import('@/views/pages/Login.vue')                
          },
        },              
        {
          path: '', 
          name: 'Home',
          meta: {needAuth: true},
          components: {
            default: () => import('@/views/Home.vue'),
            // header: Header,
            // footer: Footer    
          },
        },           
        {
          path: 'contact', 
          name: 'contact',
          meta: {needAuth: true},
          components: {
            default: () => import('@/views/pages/Contact.vue'),
            // header: Header,
            // footer: Footer    
          },
        },  
        {
          path: 'project', 
          name: 'project',
          meta: {needAuth: true},
          components: {
            default: () => import('@/views/pages/Project.vue'),
            // header: Header,
            // footer: Footer    
          },
        },    
        {
          path: 'location', 
          name: 'location',
          meta: {needAuth: true},
          components: {
            default: () => import('@/views/pages/Location.vue'),
            // header: Header,
            // footer: Footer    
          },
        },  
        {
          path: 'interior', 
          name: 'interior',
          meta: {needAuth: true},
          components: {
            default: () => import('@/views/pages/Interior.vue'),
            // header: Header,
            // footer: Footer    
          },
        },   
        {
          path: 'services', 
          name: 'services',
          meta: {needAuth: true},
          components: {
            default: () => import('@/views/pages/Services.vue'),
            // header: Header,
            // footer: Footer    
          },
        },  
        {
          path: 'wellness', 
          name: 'wellness',
          meta: {needAuth: true},
          components: {
            default: () => import('@/views/pages/Wellness.vue'),
            // header: Header,
            // footer: Footer    
          },
        },   
        {
          path: 'fitness', 
          name: 'fitness',
          meta: {needAuth: true},
          components: {
            default: () => import('@/views/pages/Fitness.vue'),
            // header: Header,
            // footer: Footer    
          },
        }, 
        {
          path: 'apartments', 
          name: 'apartments',
          meta: {needAuth: true},
          components: {
            default: () => import('@/views/pages/Apartments.vue'),
            // header: Header,
            // footer: Footer    
          },
        },  
        {
          path: 'apartments/:building_name', 
          name: 'levels',
          meta: {needAuth: true},
          components: {
            default: () => import('@/views/pages/Levels.vue'),
            // header: Header,
            // footer: Footer    
          },
        },    
        {
          path: 'apartments/:building_name/:level', 
          name: 'flats',
          meta: {needAuth: true},
          components: {
            default: () => import('@/views/pages/Flats.vue'),
            // header: Header,
            // footer: Footer    
          },
        },  
        {
          path: 'apartments/:building_name/:level/:flat_slug', 
          name: 'flatDetails',
          meta: {needAuth: true},
          components: {
            default: () => import('@/views/pages/FlatDetails.vue'),
            // header: Header,
            // footer: Footer    
          },
        }       
      ]
    },        
    {
      path: '*',          
      redirect: {name: 'LogIn', params:{lang: 'hu'}}
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  
})


router.beforeEach((to, from, next) => {  
  document.getElementsByTagName('html')[0].scrollTop = 0    
  
    //SELECT LANG
    // Get language from path
    const languageUrlSegment = to.path.split('/');  
    languageUrlSegment.shift();
    let language = languageUrlSegment[0];  
    // console.log('beforeach lang', language)
    if (!language) language = store.state.lang.defLang; //ide inkább a mixin selectedlangja kellene
    if (store.state.languages.findIndex(item => item.code === language) > -1){
      // Set language in the store    
      to.params.language = language;
      store.state.lang.selectedLang = language
    } else {
      language = store.state.lang.defLang
    }   
    // console.log('beforeach lang', store.state.lang.selectedLang)   

  //AUTH
  if (to.matched.some(record => record.meta.needAuth)) {    
    //USER LOGIN
    if (store.state.token === '') {
      if (localStorage.getItem(CONFIG.localStorageName) !== null) {        
        store.state.token = localStorage.getItem(CONFIG.localStorageName);
      }
    }    
    if (store.state.token !== '') {
      axios.get(CONFIG.apiUrl + "checktoken", {
        params: {
          token: store.state.token
        }
      })     
      .then((response) => {
          store.state.user = response.data.user;
          next();
      }).catch(() => {
          store.state.token = '';
          localStorage.removeItem(CONFIG.localStorageName);
          next({
            name: 'LogIn',            
            params:{lang:store.state.lang.selectedLang}
            // query: {redirect: to.fullPath}
          })
        })      
    } else {
      next({
        name: 'LogIn',        
        params:{lang: store.state.lang.selectedLang}
        // query: {redirect: to.fullPath}
      })
    }    
  } else if (to.matched.some(record => record.meta.needAdminAuth)){
    // ADMIN LOGIN
        if (store.state.token === '') {
          if (localStorage.getItem(CONFIG.localStorageNameAdmin) !== null) {        
            store.state.token = localStorage.getItem(CONFIG.localStorageNameAdmin);
          }
        }    
        if (store.state.token !== '') {
          axios.get(CONFIG.apiUrl + "checktoken", {
            params: {
              token: store.state.token
            }
          })     
          .then((response) => {
              if (response.data.user.user_type === CONFIG.adminType) {
                store.state.user = response.data.user;
                next();
              } else {
                store.state.token = '';
                localStorage.removeItem(CONFIG.localStorageNameAdmin);
                next({
                  path: '/admin',
                  // query: {redirect: to.fullPath}
                })
              }              
          }).catch(() => {
              store.state.token = '';
              localStorage.removeItem(CONFIG.localStorageNameAdmin);
              next({
                path: '/admin',
                // query: {redirect: to.fullPath}
              })
            })      
        } else {
          next({
            path: '/admin',
            // query: {redirect: to.fullPath}
          })
        }   
  } else if (to.meta.defaultLoginName) {

    if (store.state.token === '') {
      if (localStorage.getItem(CONFIG.localStorageName) !== null) {        
        store.state.token = localStorage.getItem(CONFIG.localStorageName);
      }
    }
    if (store.state.token !== '') {
      axios.get(CONFIG.apiUrl + "checktoken", {
        params: {
          token: store.state.token
        }
      })
        .then((response) => {
          store.state.user = response.data.user;
          next({name: to.meta.defaultLoginName});
        })
        .catch(() => {
          store.state.token = '';
          localStorage.removeItem(CONFIG.localStorageName);
          next();
        })
    } else {
      next();
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
