import Vue from 'vue'
import App from './App.vue'
import router from './setup/router.js'
import store from './setup/store.js'
import * as CONFIG from '@/setup/init.js';

//Boostrap
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

//plugins
import VueScrollTo from 'vue-scrollto';
// import VueAgile from 'vue-agile'
import VueObserveVisibility from 'vue-observe-visibility'

//css
import '@/css/main.min.css'

//error msg-s
import customErrorMessages from "@/assets/errorMessages.json";

//axios
import axios from 'axios'
Vue.prototype.$axios = axios


Vue.use(VueObserveVisibility)
Vue.use(BootstrapVue)
// Vue.use(VueAgile)

Vue.use(VueScrollTo, {
  container: "body",
  duration: 750,
  easing: "ease",
  offset: -50,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.mixin({
  data(){
    return{
      baseUrl: process.env.VUE_APP_BASE_URL,
      CONFIG: CONFIG,
      errorMessages: customErrorMessages
    }
  },
  computed:{
    selectedLanguage(){
      return this.$store.state.lang.selectedLang ?? this.$store.state.lang.defLang
    }
  },
  created(){
    // setTimeout(this.lazyLoadImg(), 1000);
  },
  methods:{  
    visibilityChanged(event, entry, customArgument, customArgument2){            

      if (event){
        let element = entry.target
        // console.log(entry)
        element.classList.add(customArgument)

        if (customArgument2){
          element.classList.add(customArgument2)
        }
      }
    },
    tr(objectOrString) {
      const obj = typeof objectOrString === 'object' ? objectOrString : (this.$store.state.langfile[`${objectOrString}`] ?? {});
      return obj[this.$store.state.lang.selectedLang] ?? obj[this.CONFIG.defaultLanguageCode] ?? '';
    }, 
    getErrorMessage(fieldName, violation) {
      let slangCode = this.$store.state.lang.selectedLang
      let dlangCode = this.$store.state.lang.defLang                 

      return violation
          ? (this.errorMessages[fieldName] &&
            slangCode ? this.errorMessages[fieldName][violation[0]][slangCode] : this.errorMessages[fieldName][violation[0]][dlangCode]
          ) ??
          violation[1]
          : undefined;

      
    },   
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
