<template>    
        <b-dropdown id="langSelect" :text="langChooserText" class="lang-selector">
            <b-dropdown-item v-for="lang in langOptions" :key="lang.code" @click="setLang(lang.code)" :class="{'hidden': lang.code === selectedLanguage}">
                <span v-if="homepage">{{lang.text}}</span>
                <span v-else class="lang-code">{{lang.code}}</span>
            </b-dropdown-item>
        </b-dropdown>
</template>

<script>
export default {
    props:['homepage'],
    data() {
        return{              
        }        
    },
    computed:{
        langOptions(){
            return this.$store.state.languages
        },
        langChooserText(){
           if (this.$store.state.lang.selectedLang){
            //    console.log('nyelvvalaszto', this.$store.state.lang.selectedLang )
               if (this.homepage){
                    return this.$store.state.languages.find(item => item.code === this.$store.state.lang.selectedLang).text
               } else {
                   return this.$store.state.languages.find(item => item.code === this.$store.state.lang.selectedLang).code
               }               
           } else if (this.homepage){
               return this.tr('lang_selector_choose')
           } else {
               return this.$store.state.lang.defLang
           }
        }
    },   
    methods:{
        setLang(newLang){            
            this.$store.state.lang.selectedLang = newLang
            this.$route.params.lang = newLang                        

            let utlpath = this.$route.path.split('/');      
            utlpath.shift()                                
            utlpath.shift() 
            let urlpathFull = utlpath.join('/')
            // console.log(utlpath, urlpathFull)
            this.$router.push('/' + newLang + '/' + urlpathFull)
        }
    }
}
</script>