<template>
    <header class="main-header sticky-header-top" >
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12">                 
                    <b-navbar toggleable="lg">
                        <router-link class="logo" :to="{name: 'Home', params:{lang: selectedLanguage}}">Luminor</router-link>
                        <div class="main-header__line"></div>
                        <b-navbar-toggle target="nav-collapse" toggleable="lg"></b-navbar-toggle>

                        <b-collapse id="nav-collapse" is-nav >
                            <b-navbar-nav class="ml-auto">                                
                                <li class="nav-item">
                                    <router-link :to="{ name: 'project', params :{lang: selectedLanguage}}" class="nav-link">
                                    {{tr('menu_project')}}</router-link>
                                </li>                              
                                <li class="nav-item">
                                    <router-link :to="{ name: 'location', params: {lang: selectedLanguage}}" class="nav-link">
                                    {{tr('menu_location')}}</router-link>
                                </li>                                
                                <li class="nav-item">
                                    <router-link :to="{ name: 'apartments', params: {lang: selectedLanguage}}" class="nav-link">
                                    {{tr('menu_apartments')}}</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'interior', params: { lang: selectedLanguage }}" class="nav-link">
                                    {{tr('menu_interior')}}</router-link>
                                </li>                                         
                                <li class="nav-item">
                                    <router-link :to="{ name: 'services', params:{lang: selectedLanguage}}" class="nav-link">{{tr('menu_services')}}</router-link>
                                </li>                      
                                <li class="nav-item">
                                    <router-link :to="{ name: 'contact', params:{ lang: selectedLanguage}}" class="nav-link">
                                    {{tr('menu_contact')}}</router-link>
                                </li>
                                <li class="nav-item">                                    
                                    <LangChooser :homepage='false'/>                                     
                                </li>
                            </b-navbar-nav>                           
                        </b-collapse>
                    </b-navbar>
                </b-col>
            </b-row>        
        </b-container>
    </header>
</template>

<script>
import LangChooser from "@/components/layout/LangSelector.vue"

export default {
    data(){
        return{
           
        }
    },
    components:{
        LangChooser
    },
    methods:{     
        
    },
    created(){
       
    },
    destroyed(){
        
    }
}
</script>