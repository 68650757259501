import Vue from 'vue'
import Vuex from 'vuex'
import * as CONFIG from '@/setup/init.js'
import axios from 'axios'

const timeStamp = Math.floor(Date.now() / 1000)

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apartments:[],
    apiCalls: [],
    token:'',
    userData: {},
    languages: CONFIG.languages, 
    lang:{      
      defLang: CONFIG.defaultLanguageCode,
      selectedLang: null   
    },     
    langfile: "lang_hu.json"    
  },
  mutations: {
    SET_AOARTMENTS(state, response) {
      state.apartments = response;
    },
    SET_USER_TOKEN(state, response) {
      state.token = response;
    },
    SET_LANGFILE(state, response){
      state.langfile = response
    },
  },
  actions: {
    getLangFile({ commit }) {
      return new Promise((resolve) => {
        axios.get(process.env.BASE_URL + 'content/lang.json?' + timeStamp).then(response => {
          commit("SET_LANGFILE", response.data)
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })
    },
    getApartments({ commit }) {
      return new Promise((resolve) => {
        axios.get(process.env.BASE_URL + 'content/apartments.json?' + timeStamp).then(response => {
          commit("SET_AOARTMENTS", response.data.buildings)
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })
    },
    setUserToken: (context, token) => {
      context.commit('SET_USER_TOKEN', token);
    },
  },
  modules: {
  }
})
