export const apiUrl = process.env.VUE_APP_API_URL
export const contactEmail = process.env.VUE_APP_EMAIL
export const adminType = "administrator";
export const defaultLanguageId = 1;
export const defaultLanguageCode = 'hu';
export const localStorageName = "lum_token"
export const localStorageNameAdmin = "la_token"
export const languages = [
    {
      id: 1,
      code: 'hu',
      text: 'Magyar'        
    },
    {
      id: 2,
      code: 'en',
      text: 'English'
    }
  ]
