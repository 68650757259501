<template>
  <div id="app">    
    <!-- <router-view name="header"/> -->    
    <Header v-if="needHeaderFooter"/>
    <router-view/>
    <Footer v-if="needHeaderFooter"/>
    <!-- <router-view name="footer"/> -->
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'

export default {
  components:{
    Header,
    Footer
  },
  computed:{
    needHeaderFooter(){
      return !(this.$route.name === 'admin' || this.$route.name === 'adminPage' || this.$route.name === 'LogIn' )
    }
  },
  created(){
    this.$store.dispatch("getApartments");
    this.$store.dispatch("getLangFile");
  },
  destroyed(){
      localStorage.removeItem(this.CONFIG.localStorageName)
      this.$store.state.token = null      
  }
}
</script>